import { useQuery } from "@apollo/react-hooks"
import { gql } from "apollo-boost"

const GET_PROFILE = gql`
    query getArtistProfile($name: String!){
        artists(where: { name: $name }) {
            about{
                bio
                headshot{
                    url
                }
            }
        }
}
`

const useProfileData = (name) => {
    const { data, loading, error } = useQuery(GET_PROFILE, { variables: { name } })
    if(error){ console.error(error) }
    return { data, loading, error }
}

export default useProfileData