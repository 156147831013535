import React from "react"
import useSiteMetadata from "../hooks/use-site-metadata"

import Page from "../components/page"
import SEO from "../components/seo"
import About from "../components/about"

const AboutPage = () => {
  const { 
    description,
    instagram,
    soundcloud,
    youtube,
    facebook,
    twitter
  } = useSiteMetadata()
  return(
    <Page>
      <SEO title="ABOUT" description={description}/>
        <About
          social={{ instagram, soundcloud, youtube, facebook, twitter }}
          quote={description}
          name="Tacitoak"
        />
    </Page>
  )
}

export default AboutPage
