import React from "react"
import PropTypes from "prop-types"

import useProfileData from "../hooks/use-profile-data"
import { renderSocialIcons, fromCDN } from "../lib/utils"

import Loader from "./loader"

import "./about.scss"

const About = ({ social, quote, name }) => {
  const { data, loading } = useProfileData(name)
  if(loading) return <Loader fullscreen palette={[`#c31432`, `#240b36`]}/>
  
  const { artists: [ profile ] } = data
  const { about: { bio, headshot } } = profile
  return(
    <>
        <section className="page__content--block no-scroll">
          <div 
            className="about__headshot animated slideInLeft"
            style={{ backgroundImage: `url(${ fromCDN(headshot.url) })` }}
          />
        </section>
        <section className="page__content--block">
            <div className="about__container animated fadeInUp">
              <h1 className="about__header">
                <header>{ name }</header>
              </h1>
              <em className="about__quote">
                "&nbsp;{ quote }&nbsp;"
              </em>
              <p className="about__bio">
                { bio }
              </p>
              <span className="about__social">
                {renderSocialIcons(social)}
              </span>
            </div>
        </section>
    </>
  )
}

About.propTypes = {
    name: PropTypes.string.isRequired,
    quote: PropTypes.string.isRequired,
    social: PropTypes.shape({
        facebook: PropTypes.string,
        instagram: PropTypes.string,
        soundcloud: PropTypes.string,
        twitter: PropTypes.string,
        youtube: PropTypes.string
    }).isRequired
}

export default About
